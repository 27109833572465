<template lang="pug">
  .site-nav-desktop(v-click-outside="closeMenu")
    ul.site-nav-wrap(itemscope itemtype="https://schema.org/SiteNavigationElement" role="menubar")
      li.site-nav-item(v-for="item in navItems" :key="item.id" role="none")
        SiteNavDesktopTopItem(:item="item")

      li.site-nav-item(role="none")
        .site-nav-title.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak
          span.site-nav-title-content
            span#extole_zone_global_header

</template>

<script>
  import SiteNavDesktopTopItem from './SiteNavDesktopTopItem';
  import SiteNavDesktopSubMenu from './SiteNavDesktopSubMenu';

  import { mapState, mapMutations, mapGetters } from 'vuex';
  import { TOP_NAV, TOP_NAV_FOR_RC } from "@components/SiteNav/constants";
  import { extoleHeaderTag } from "@services/extoleSvc";

  import menuMixin from "@mixins/menuMixin";

  export default {
    name: "SiteNavDesktop",

    components: {
      SiteNavDesktopTopItem,
      SiteNavDesktopSubMenu
    },

    mixins: [menuMixin],

    computed: {
      ...mapState('siteNav', [
        'selectedSiteDesktopNav'
      ]),
      ...mapGetters('customer', [
        'isCustomerPresent',
      ]),

      topNav() {
        if (!this.isCustomerPresent) {
          return TOP_NAV;
        }
        return TOP_NAV_FOR_RC;
      },

      navItems() {
        return this.mix_copyOnlyElementsToShow(this.topNav.navItems);
      },

    },

    mounted() {
      extoleHeaderTag();

      this.mix_notifyExperimentsViewed(this.topNav.navItems, ['navItems', 'subCategories']);
    },

    methods: {
      ...mapMutations('siteNav', [
        'setSelectedSiteDesktopNav'
      ]),

      closeMenu() {
        if (this.selectedSiteDesktopNav) {
          this.setSelectedSiteDesktopNav(0);
        }
      }
    },
  };
</script>

<style scoped lang="stylus">
  .site-nav-desktop
    padding-bottom 1px

    .site-nav-item
      display inline-block
      vertical-align text-bottom
      color text-color-1
      border 1px solid transparent

      button
        background-color transparent
        border none
        padding 0

      .site-nav-title-content
        display block
        padding 0.5em 1.2em

</style>