import vueAddressSvc from "@services/vueAddressSvc";
import vueSubscriptionSvc from "@services/vueSubscriptionSvc";

import { required, minLength, maxLength } from "@vuelidate/validators";
import { mrPhoneValidator } from '@utilities/customValidators';

const state = {
  addresses: [],
  editAddressForm: {},
  selectedAddressId: null,
  lastSavedAddress: {},
  savingAddress: false,
  regions: [],
  validatedAddress: {},
  addressToDelete: {},
  loadingAddresses: false,
  postSaveMethod: null,
  addressKeys: ['first_name', 'last_name', 'street_1', 'street_2', 'post_code', 'city', 'region', 'phone'],
  addressFormValidators: {
    first_name: {
      required,
      minLength: minLength(2)
    },

    last_name: {
      required,
      minLength: minLength(2)
    },

    street_1: {
      required
    },

    post_code: {
      required,
      maxLength: maxLength(5),
      minLength: minLength(5)
    },

    city: {
      required,
      minLength: minLength(2)
    },

    region: {
      required
    },

    phone: {
      mrPhoneValidator,
      required
    },

    email: {}
  },
  errorMessage: null
};

export const getters = {
  hasAddress(state) {
    return !!(state.addresses && state.addresses.length);
  },

  defaultShipAddress(state) {
    return state.addresses.find(address => address.is_default_shipping);
  },

  selectedAddress(state) {
    return state.addresses.find(address => address.id == state.selectedAddressId);
  },

  paymentEditAddressValidator(state) {
    let validator = Object.assign({}, state.addressFormValidators);
    delete validator.phone;
    return validator;
  },

  showDefaultAddressCheckbox(state) {
    //- show when:
    //- address in edit is not billingAddress
    //- customer has more than one address
    //- or when customer has 1 address and is not trying
    //- to delete it while replacing with new address
    return !state.editAddressForm.isBillingAddress &&
      (state.addresses.length > 1 ||
      (state.addresses.length == 1 && !state.addressToDelete.id));
  },

  getAddressById: (state) => (id) => {
    return state.addresses.find(address => address.id == id) || {};
  },

  selectedAddressObject(state, getters) {
    return getters.getAddressById(state.selectedAddressId);
  }
};

export const actions = {
  //- use this when need to ensure that addresses are loaded
  initAddresses({ state, dispatch }) {
    if (state.addresses.length) {
      return;
    }
    dispatch('getAllAddressesByCustomerId');
  },
  //- address refresh/load
  getAllAddressesByCustomerId({ commit }) {
    commit('setLoadingAddresses', true);
    return vueAddressSvc.getAllAddressesByCustomerId()
      .then(res => {
        commit('setAddressList', res.data);
      }).finally(() => {
        commit('setLoadingAddresses', false);
      });
  },

  getAllAddressesAndSelectDefault({ commit, getters, dispatch }) {
    dispatch('getAllAddressesByCustomerId').then(() => {
      if (getters.defaultShipAddress) {
        commit('setSelectedAddressId', getters.defaultShipAddress.id);
      }
    });
  },

  populateEditAddressForm({ commit, dispatch }, address) {
    dispatch('updateLastSavedAddress');
    if (!address) {
      return vueAddressSvc.newAddress()
        .then(res => {
          res.data.formIsValid = false;
          commit('setEditAddressForm', res.data);
          commit('updateDefaultShipping', true);
        });
    } else {
      if (!address.is_default_shipping) {
        commit('updateDefaultShipping', true);
      }
      commit('setEditAddressForm', address);
    }

    if (address && Object.prototype.hasOwnProperty.call(address, 'isBillingAddress')) {
      commit('setIsBillingAddress', address.isBillingAddress);
    }
  },

  saveAddressForm({ commit, dispatch, state }) {
    if (state.savingAddress) {
      return;
    }

    commit('setSavingAddress', true);
    //- pass it through usps validation
    return new Promise((resolve, reject) => {
      vueAddressSvc.validateAddress(state.paymentProfile)
        .then(res => {
          //- compare validated and entered address
          commit('setValidatedAddress', res.data);

          var addrKeys = ["street_1", "street_2", "post_code", "city", "region"];
          for (var i = 0; i < addrKeys.length; i++) {
            // if address does't match open confirm modal
            var key = addrKeys[i];
            var addrPart = state.editAddressForm[key] || '';
            var validPart = state.validatedAddress[key] || '';
            if (addrPart.toUpperCase() != validPart.toUpperCase()) {
              //- if usps has suggestion prompt user to review
              dispatch('addressVerifyModal');
              //- by rejecting we ensure that EditAddressModal does not get closed
              //- and instead gets just replaced in order to preserve any modal.stepBackActions
              return reject();
            }
          }
          //- save validated address
          resolve();
          return dispatch('saveAddress');
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.code && err.response.data.code == 'INVALID_ADDRESS') {
            commit('setValidatedAddress', {});
            //- if address is not valid and doesn't have corrected suggestions prompt user to confirm save with invalid address skipping usps validation
            dispatch('addressVerifyModal');
            return reject();
          }

          dispatch('notifyError', 'Your shipping information is invalid. Please review.', { root: true });
          commit('setSavingAddress', false);
          reject();
        });
    });
  },

  saveAddress({ commit, dispatch, state }) {
    if (!state.savingAddress) {
      commit('setSavingAddress', true);
    }

    return vueAddressSvc.saveAddress(state.editAddressForm)
      .then((res) => {
        commit('setSavingAddress', false);
        dispatch('notifySuccess', 'Thanks! Your shipping information has been updated.', { root: true });
        //- because we allow user to save a new address while delete
        //- an address in one swoop lets check if there is one set for deletion
        if (state.addressToDelete.id) {
          return dispatch('deleteAddress');
        }

        commit('clearEditAddressForm');
        commit('setSelectedAddressId', res.data.id);
        dispatch('updateLastSavedAddress', res.data);
        dispatch('getAllAddressesByCustomerId').then(() => {
          if (state.postSaveMethod) {
            state.postSaveMethod();
            commit('setPostSaveMethod', null);
          }
        });
      })
      .catch(err => {
        commit('setSavingAddress', false);

        if (err && err.data && err.data.code == "REST_ACCESS_DENIED") {
          dispatch('notifyError', 'We were unable to authenticate your user, please log in again.', { root: true });
          var location = window.location;
          window.location = location;
        } else {
          dispatch('notifyError', 'Your shipping information is invalid. Please review.', { root: true });
        }

      });
  },

  updatePostCodeWithZipCheck({ state, commit }, post_code) {
    let vuexState = state;
    commit('updatePostCode', post_code);

    if (vuexState.errorMessage) {
      commit('setErrorMessage', null);
    }

    if (!post_code || post_code.length < 5) {
      return;
    }

    return vueAddressSvc.zipCheck(post_code).then(res => {
      var { city, state } = res.data;
      //- if zipCheck is successful prepopulate city and state
      if (city && state) {
        if (!vuexState.regions.some(region => region.code == state)) {
          commit('updateCity', null);
          commit('updateRegion', null);
          commit('setErrorMessage', 'We only ship within the United States of America and some US Territories.');
        } else {
          commit('updateCity', city);
          commit('updateRegion', state);
        }
      }
    }).catch(err => {
      /* istanbul ignore else*/
      if (err.data && err.data.message == 'Invalid Zip Code.') {
        commit('updateCity', null);
        commit('updateRegion', null);
      }
    });
  },

  editAddressModal({ dispatch }, address) {
    dispatch('populateEditAddressForm', address);
    dispatch('modal/showModal', {
      component: 'AddressEditModal',
    }, { root: true });
  },

  selectOrAddAddressModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: 'SelectOrAddAddressModal'
    }, { root: true });
  },

  updateSubsModal({ commit, dispatch }) {
    dispatch('modal/showModal', {
      component: 'AddressUpdateSubsModal',
    }, { root: true });
    commit('modal/setPersistent', true, { root: true });
  },

  addressVerifyModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: 'AddressVerifyModal',
    }, { root: true });
  },

  clearEditAddressForm({ commit }) {
    commit('clearEditAddressForm');
  },

  deleteAddressModal({ commit, dispatch }, address) {
    commit('setAddressToDelete', address);
    let modal = './AddressBook/AddressDeleteModal';
    if (address.subs.length) {
      modal = './AddressBook/AddressDeleteHasSubsModal';
    }

    dispatch('modal/showModal', {
      component: modal,
    }, { root: true });
  },

  deleteAddressWithSubReplace({ commit, dispatch, state }, newAddressId) {
    var subIds = state.addressToDelete.subs.map(sub => sub.id);

    //- if addressId passed use that for all subs
    if (newAddressId) {
      //- update all subs to new addressId and then delete the address
      return dispatch('updateShipAddressForSubscriptions', { newAddressId, subIds })
        .then(() => {
          dispatch('deleteAddress');
          dispatch('subscriptions/loadCustomerSubs', null, { root:true });
        });
    }
    //- else check for new addressForm and save new address then use that for subsIds
    //- which gets handled in saveAddress action
    commit('updateAddressForSubs', subIds);
    dispatch('saveAddressForm');
  },

  updateShipAddressForSubscriptions({ dispatch }, params) {
    var payload = {
      subIds: params.subIds,
      addressId: params.newAddressId
    };

    return new Promise((resolve, reject) => {
      vueSubscriptionSvc.updateShipAddressForSubscriptions(payload)
        .then(() => {
          dispatch('notifySuccess', 'Shipping address for all auto-deliveries has been updated', { root: true });
          resolve();
        })
        .catch(() => {
          dispatch('notifyError', null, { root: true });
          reject();
        });
    });
  },

  deleteAddress({ commit, dispatch, state }) {
    let address_id = state.addressToDelete.id;
    if (!address_id) {
      return dispatch('notifyError', 'No address has been selected for deletion', { root: true });
    }

    return vueAddressSvc.deleteAddress({ address_id })
      .then(() => {
        if (address_id == state.selectedAddressId) {
          //- clear selectedAddressId if we just deleted that address
          commit('setSelectedAddressId', null);
        }
        commit('clearAddressToDelete');
        commit('clearEditAddressForm');
        dispatch('getAllAddressesByCustomerId');
        dispatch('notifySuccess', 'Your address was successfully removed.', { root: true });
      })
      .catch(() => {
        dispatch('notifyError', null, { root: true });
      });
  },

  getRegionsByCountry({ commit, state }) {
    //- have to be improved when going international
    if (state.regions.length) {
      return;
    }

    return vueAddressSvc.getRegionsByCountry({ country: 'US' }).then(res => {
      commit('setRegions', res.data);
    });
  },

  clearSelectedAddress({ commit }) {
    commit('setSelectedAddressId', null);
  },

  updateLastSavedAddress({ commit }, val) {
    commit('setLastSavedAddress', val || {});
  }
};

export const mutations = {
  setAddressList(state, addresses) {
    state.addresses = addresses;
  },

  setEditAddressForm(state, address) {
    state.editAddressForm = Object.assign({}, address);
  },

  setLoadingAddresses(state, val) {
    state.loadingAddresses = Boolean(val);
  },

  setValidatedAddress(state, address) {
    state.validatedAddress = address;
  },

  setPrevalidatedAddress(state, address) {
    state.preValidatedAddress = address;
  },

  setAddressToDelete(state, address) {
    state.addressToDelete = address;
  },

  setIsBillingAddress(state, val) {
    state.editAddressForm.isBillingAddress = val;
  },

  setSavingAddress(state, val) {
    state.savingAddress = val;
  },

  setSelectedAddressId(state, val) {
    state.selectedAddressId = val;
  },

  setLastSavedAddress(state, val) {
    state.lastSavedAddress = val || {};
  },

  updateAddressForSubs(state, val) {
    state.editAddressForm.update_address_for_subs = val;
  },

  updateUseAddressForAllSubs(state, val) {
    state.editAddressForm.use_address_for_all_subscriptions = val;
  },

  updateFirstName(state, val) {
    state.editAddressForm.first_name = val;
  },

  updateLastName(state, val) {
    state.editAddressForm.last_name = val;
  },

  updateStreet1(state, val) {
    state.editAddressForm.street_1 = val;
  },

  updateStreet2(state, val) {
    state.editAddressForm.street_2 = val;
  },

  updatePostCode(state, val) {
    state.editAddressForm.post_code = val;
  },

  updateCity(state, val) {
    state.editAddressForm.city = val;
  },

  updateRegion(state, val) {
    state.editAddressForm.region = val;
  },

  updatePhone(state, val) {
    state.editAddressForm.phone = val;
  },

  updateDefaultShipping(state, val) {
    state.editAddressForm.is_default_shipping = val;
  },

  updateSkipValidation(state, val) {
    state.editAddressForm.skip_usps = val;
  },

  setRegions(state, regions) {
    state.regions = regions;
  },

  clearEditAddressForm(state) {
    state.editAddressForm = {};
  },

  clearAddressToDelete(state) {
    state.addressToDelete = {};
  },

  setPostSaveMethod(state, val) {
    state.postSaveMethod = val;
  },

  setErrorMessage(state, val) {
    state.errorMessage = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
