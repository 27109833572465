<template lang='pug'>
  .image-box(ref="box")
    img(v-if="src" :srcset="srcSet" :src="src" :alt="mediaObject.alt_text || ''" :loading="loading" :width="width" :height="height" data-test="img-box")
</template>

<script>
  import { getMediaObject } from '@services/vueMediaSvc';

  export default {
    name: 'ImgBox',
    props: {
      default: () => {},
      mediaId: [String, Number],
      mediaObj: Object,
      loading: {
        type: String,
        required: false,
        default: 'lazy'
      },
      quality: {
        type: Number
      }
    },

    data: function() {
      return {
        width: 0,
        height: 0,
        interval: null,
        asyncMediaObj: null
      };
    },

    watch: {
      mediaId: {
        handler: 'loadMediaObject',
        immediate: true
      }
    },

    computed: {
      mediaObject() {
        return this.asyncMediaObj || this.mediaObj;
      },

      aspect() {
        var aspect = {};
        if (!this.width || !this.height || !this.mediaObject || !this.mediaObject.url) {
          return aspect;
        }

        aspect.url = this.mediaObject.url;
        aspect.crop = `&w=${this.width}&h=${this.height}&fit=crop&crop=faces`;

        var watermarkParams = this.getWatermarkParams(this.mediaObject.watermark);
        if (watermarkParams) {
          aspect.crop += `&${this.getWatermarkParams(this.mediaObject.watermark)}`;
        }

        var targetRatio = this.width / this.height;
        var temp = targetRatio;
        (this.mediaObject.aspects || []).forEach(mediaAspect => {
          var ratioDifference = Math.abs(mediaAspect.m - targetRatio);
          if (ratioDifference <= temp) {
            temp = ratioDifference;
            aspect.url = mediaAspect.url || this.mediaObject.url;
            aspect.crop = `${mediaAspect.cropQuery}&w=${this.width}&h=${this.height}`;
            var watermarkParams = this.getWatermarkParams(this.mediaObject.watermark, mediaAspect);
            if (watermarkParams) {
              aspect.crop += `&${watermarkParams}`;
            }
            aspect.mediaAspect = mediaAspect;
          }
        });

        return aspect;
      },

      qualitySrcParam() {
        if (this.quality) {
          return '&q=' + this.quality;
        } else if (this.mediaObject && this.mediaObject.quality) {
          return '&q=' + this.mediaObject.quality;
        }
        return '';
      },

      src() {
        if (!this.aspect || !this.aspect.url) {
          return '';
        }
        let url = this.remapUrl(this.aspect.url);

        // replace white spaces
        url = url.replace(/ /gi, '%20');

        return `${url}?${this.aspect.crop}&auto=format${this.qualitySrcParam}`;
      },

      srcSet() {
        return `${this.src} 1x,${this.src}&dpr=2 2x,${this.src}&dpr=3 3x`;
      }
    },

    methods: {
      getWidthAndHeight() {
        var clientWidth = this.$el.clientWidth;
        var clientHeight = this.$el.clientHeight;
        this.width = clientWidth;
        this.height = clientHeight + 1;
      },

      remapUrl(url) {
        if (!url) {
          return url;
        }
        var imgixHostMap = {
          'd3ewrnwdcmri66.cloudfront.net': 'mr-cdn.imgix.net',
          'ddxs94deh28np.cloudfront.net': 'mr-cdn-staging.imgix.net'
        };
        var hostRE = /https?:\/\/([^/]+)\/.*$/;
        var awsHost = url.replace(hostRE, '$1');
        var imgxHost = imgixHostMap[awsHost];
        if (imgxHost) {
          return url.replace(awsHost, imgxHost);
        }
        return url;
      },

      getWatermarkParams(watermarkObj, mediaAspect) {
        if (!watermarkObj || !watermarkObj.url) {
          return '';
        }
        mediaAspect = mediaAspect || {};
        var markparams = [];
        var markalign = mediaAspect.markalign || watermarkObj.markalign;
        var markpad = mediaAspect.markpad || watermarkObj.markpad;
        var markw = mediaAspect.markw || watermarkObj.markw;
        var markh = mediaAspect.markh || watermarkObj.markh;
        markparams.push('mark=' + watermarkObj.url);
        markparams.push('&markalign=' + markalign);
        markparams.push('&markpad=' + markpad);
        markparams.push('&markfit=clip&markw=' + markw + '&markh=' + markh);
        return markparams.join('');
      },

      async loadMediaObject() {
        if (this.mediaId) {
          const res = await getMediaObject({
            id: this.mediaId
          });
          this.asyncMediaObj = res.data;
        }
      }
    },

    mounted() {

      var self = this;
      self.getWidthAndHeight();
      self.interval = window.setInterval(function() {
        self.getWidthAndHeight();
      }, 300);
    },

    beforeDestroy() {
      clearInterval(this.interval);
    }
  };
</script>

<style lang='stylus'>
  .image-box
    width 100%
    height 100%
    overflow hidden

    img
      width 100%
      vertical-align middle
      font-size 0px

</style>
