import vueAdvisorSvc from '@services/vueAdvisorSvc';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      advisorUpdated: 0,
      advisorDataVal: null,
      _advisorDataLoaded: false,
      colorMatchesVal: null,
      _colorMatchesLoaded: false,
      moreColorOptionsVal: null,
      _moreColorOptionsLoaded: false,
      lightWorksRecommendationVal: null,
      _lightWorksRecommendationLoaded: false,
    };
  },

  computed: {
    formatedColorMatches() {
      const matches = [];
      if (this.colorMatches) {
        if (this.colorMatches.bestMatch) {
          matches.push({
            key: 'bestMatch',
            label: "Best<br/> match",
            product: this.colorMatches.bestMatch,
          });
        }
        if (this.colorMatches.mostPopular) {
          matches.push({
            key: 'mostPopular',
            label: "Most<br/> popular",
            product: this.colorMatches.mostPopular,
          });
        }
        if (this.colorMatches.customerMatch && this.uniqueMatch('customerMatch')) {
          matches.push({
            key: 'customerMatch',
            label: "Your<br/> choice",
            product: this.colorMatches.customerMatch,
          });
        }
        if (this.colorMatches.coloristMatch && this.uniqueMatch('coloristMatch')) {
          matches.push({
            key: 'coloristMatch',
            label: "Colorist's<br/> choice",
            product: this.colorMatches.coloristMatch,
          });
        }
        if (this.colorMatches.translatorMatch) {
          matches.push({
            key: 'translatorMatch',
            label: "Translator<br/> results",
            product: this.colorMatches.translatorMatch,
          });
        }
      }
      return matches;
    },

    advisorAnswers() {
      return this.advisorData ? this.advisorData.answers : undefined;
    },

    surveyAnswers() {
      return this.advisorData ? this.advisorData.surveys : undefined;
    },

    advisorUpdatedAt() {
      return this.advisorData ? this.advisorData.updated_at : undefined;
    },

    advisorData() {
      if (!this._advisorDataLoaded) {
        this._advisorDataLoaded = true;
        vueAdvisorSvc.getCustomerAnswers().then(res => {
          this.advisorDataVal = res.data;
        });
      }
      return this.advisorDataVal;
    },

    colorMatches() {
      if (!this._colorMatchesLoaded) {
        this._colorMatchesLoaded = true;
        vueAdvisorSvc.getAllColorMatches()
          .then(res => {
            this.colorMatchesVal = res.data;
          })
          .catch(() => {
            this.colorMatchesVal = null;
          });
      }
      return this.colorMatchesVal;
    },

    moreColorOptions() {
      if (!this._moreColorOptionsLoaded) {
        this._moreColorOptionsLoaded = true;
        vueAdvisorSvc.getMoreColorOptions()
          .then(res => {
            this.moreColorOptionsVal = res.data;
          })
          .catch(() => {
            this.moreColorOptionsVal = null;
          });
      }
      return this.moreColorOptionsVal;
    },

    lightWorksRecommendation() {
      if (!this._lightWorksRecommendationLoaded) {
        this._lightWorksRecommendationLoaded = true;
        vueAdvisorSvc.getLightWorksRecommendations().then(res => {
          if (res && res.data && res.data.id) {
            this.lightWorksRecommendationVal = res.data[0];
          } else {
            this.lightWorksRecommendationVal = null;
          }
        });
      }
      return this.lightWorksRecommendationVal;
    },
  },

  watch: {
    advisorUpdated() {
      this._advisorDataLoaded = false;
      this.advisorDataVal = null;
      this._colorMatchesLoaded = false;
      this.colorMatchesVal = null;
      this._moreColorOptionsLoaded = false;
      this.moreColorOptionsVal = null;
      this._lightWorksRecommendationLoaded = false;
      this.lightWorksRecommendationVal = null;
    },
  },

  methods: {
    ...mapActions('xsell', ['xsellOnAdvisorUpdate']),
    ...mapActions('colorAdvisor', ['isAdvisorCompleted']),
    uniqueMatch(checkKey) {
      let unique = true;
      if (this.colorMatches) {
        Object.keys(this.colorMatches).forEach(key => {
          if (key !== checkKey) {
            if (this.colorMatches[key] && this.colorMatches[key].code === this.colorMatches[checkKey].code) {
              unique = false;
            }
          }
        });
      }
      return unique;
    },

    persistCustomerAnswers(params) {
      return new Promise((resolve, reject) => {
        vueAdvisorSvc.persistCustomerAnswers({ answers: params })
          .then(res => {
            this.advisorUpdated += 1;
            this.isAdvisorCompleted();
            this.xsellOnAdvisorUpdate();
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
