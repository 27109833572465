import vueXsellSvc from '@services/vueXsellSvc';

export default {
  data() {
    return {
      xsellUpdated: 0,
      xsellVal: null,
      xsellByContextVal: null,
      _xsellLoaded: false,
      _xsellByContextLoaded: false,
    };
  },
  computed: {
    bestMatches() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.bestMatches;
    },
    cartAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.historyAddons;
    },
    historyAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.historyAddons;
    },
    lastOrderAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.lastOrderXSell;
    },
    subscriptionAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.subscriptionXSell;
    },
    xsell() {
      if (!this._xsellLoaded) {
        this._xsellLoaded = true;
        vueXsellSvc.getXsellItems().then(res => {
          this.xsellVal = res.data;
        });
      }
      return this.xsellVal;
    },
    xsellByContext() {
      if (!this._xsellByContextLoaded) {
        this._xsellByContextLoaded = true;
        vueXsellSvc.getXsellByContext().then(res => {
          this.xsellByContextVal = res.data;
        });
      }
      return this.xsellByContextVal;
    },
  },
  watch: {
    xsellUpdated() {
      this._xsellLoaded = false;
      this.xsellVal = null;
      this._xsellByContextLoaded = false;
      this.xsellByContextVal = null;
    },
  },
};
